import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1e880830&scoped=true"
import script from "./index.vue?vue&type=script&setup=true&lang=js"
export * from "./index.vue?vue&type=script&setup=true&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=1e880830&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e880830",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextHtml: require('/var/www/html/glamoraV2/frontend-2/components/text/html/index.vue').default,Btn: require('/var/www/html/glamoraV2/frontend-2/components/btn/index.vue').default})
