import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4ebb841c"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnNavPrimary: require('/var/www/html/glamoraV2/frontend-2/components/btn/nav/primary/index.vue').default,Toolbar: require('/var/www/html/glamoraV2/frontend-2/components/toolbar/index.vue').default})
