import { render, staticRenderFns } from "./index.vue?vue&type=template&id=25237cc9"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlockTextHeader: require('/var/www/html/glamoraV2/frontend-2/components/block/text/header/index.vue').default,BlocksWrapper: require('/var/www/html/glamoraV2/frontend-2/components/blocks-wrapper/index.vue').default,BlockBannerHero: require('/var/www/html/glamoraV2/frontend-2/components/block/banner/hero/index.vue').default,BlockGrid: require('/var/www/html/glamoraV2/frontend-2/components/block/grid/index.vue').default,Page: require('/var/www/html/glamoraV2/frontend-2/components/page/index.vue').default})
