import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5fa426ec"
import script from "./index.vue?vue&type=script&setup=true&lang=js"
export * from "./index.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnSecondary: require('/var/www/html/glamoraV2/frontend-2/components/btn/secondary/index.vue').default,BtnPrimary: require('/var/www/html/glamoraV2/frontend-2/components/btn/primary/index.vue').default,FormCardProductPresentation: require('/var/www/html/glamoraV2/frontend-2/components/form/card-product-presentation/index.vue').default,InputCheckboxGroup: require('/var/www/html/glamoraV2/frontend-2/components/input/checkbox/group/index.vue').default,Loader: require('/var/www/html/glamoraV2/frontend-2/components/loader/index.vue').default,ModalFacets: require('/var/www/html/glamoraV2/frontend-2/components/modal/facets/index.vue').default,Toolbar: require('/var/www/html/glamoraV2/frontend-2/components/toolbar/index.vue').default})
