import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0eea7eb8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0470dda2 = () => interopDefault(import('../pages/_locale/index.vue' /* webpackChunkName: "pages/_locale/index" */))
const _942d6df0 = () => interopDefault(import('../pages/_locale/404.vue' /* webpackChunkName: "pages/_locale/404" */))
const _71b36774 = () => interopDefault(import('../pages/_locale/advanced-search/index.vue' /* webpackChunkName: "pages/_locale/advanced-search/index" */))
const _c20cdfa6 = () => interopDefault(import('../pages/_locale/bespoke-design-service/index.vue' /* webpackChunkName: "pages/_locale/bespoke-design-service/index" */))
const _c6733fb0 = () => interopDefault(import('../pages/_locale/brand-tools/index.vue' /* webpackChunkName: "pages/_locale/brand-tools/index" */))
const _0d0efbfa = () => interopDefault(import('../pages/_locale/cart/index.vue' /* webpackChunkName: "pages/_locale/cart/index" */))
const _76af64e6 = () => interopDefault(import('../pages/_locale/catalogues/index.vue' /* webpackChunkName: "pages/_locale/catalogues/index" */))
const _67e038a9 = () => interopDefault(import('../pages/_locale/checkout/index.vue' /* webpackChunkName: "pages/_locale/checkout/index" */))
const _18716e90 = () => interopDefault(import('../pages/_locale/company/index.vue' /* webpackChunkName: "pages/_locale/company/index" */))
const _fa59c31a = () => interopDefault(import('../pages/_locale/confirm-account/index.vue' /* webpackChunkName: "pages/_locale/confirm-account/index" */))
const _06bd8b33 = () => interopDefault(import('../pages/_locale/contacts.vue' /* webpackChunkName: "pages/_locale/contacts" */))
const _3658c114 = () => interopDefault(import('../pages/_locale/contacts/index.vue' /* webpackChunkName: "pages/_locale/contacts/index" */))
const _c6e1f144 = () => interopDefault(import('../pages/_locale/contacts/_id.vue' /* webpackChunkName: "pages/_locale/contacts/_id" */))
const _1a770164 = () => interopDefault(import('../pages/_locale/cookie-policy/index.vue' /* webpackChunkName: "pages/_locale/cookie-policy/index" */))
const _3badfa62 = () => interopDefault(import('../pages/_locale/creative-collection/index.vue' /* webpackChunkName: "pages/_locale/creative-collection/index" */))
const _41d82456 = () => interopDefault(import('../pages/_locale/diary/index.vue' /* webpackChunkName: "pages/_locale/diary/index" */))
const _63b9242e = () => interopDefault(import('../pages/_locale/faq/index.vue' /* webpackChunkName: "pages/_locale/faq/index" */))
const _51c3c29b = () => interopDefault(import('../pages/_locale/glamora-showroom/index.vue' /* webpackChunkName: "pages/_locale/glamora-showroom/index" */))
const _681240c7 = () => interopDefault(import('../pages/_locale/info-and-legal/index.vue' /* webpackChunkName: "pages/_locale/info-and-legal/index" */))
const _03bb9194 = () => interopDefault(import('../pages/_locale/materials-and-installation/index.vue' /* webpackChunkName: "pages/_locale/materials-and-installation/index" */))
const _3317f18c = () => interopDefault(import('../pages/_locale/privacy-policy/index.vue' /* webpackChunkName: "pages/_locale/privacy-policy/index" */))
const _bd6ac9a2 = () => interopDefault(import('../pages/_locale/professional-consulting/index.vue' /* webpackChunkName: "pages/_locale/professional-consulting/index" */))
const _48fdc07f = () => interopDefault(import('../pages/_locale/professionals/index.vue' /* webpackChunkName: "pages/_locale/professionals/index" */))
const _1ec8e00a = () => interopDefault(import('../pages/_locale/signup/index.vue' /* webpackChunkName: "pages/_locale/signup/index" */))
const _b6985ea8 = () => interopDefault(import('../pages/_locale/special-editions/index.vue' /* webpackChunkName: "pages/_locale/special-editions/index" */))
const _6af8c06c = () => interopDefault(import('../pages/_locale/terms-of-sale/index.vue' /* webpackChunkName: "pages/_locale/terms-of-sale/index" */))
const _b63932ee = () => interopDefault(import('../pages/_locale/account/moodboards/index.vue' /* webpackChunkName: "pages/_locale/account/moodboards/index" */))
const _5ca7552a = () => interopDefault(import('../pages/_locale/account/orders/index.vue' /* webpackChunkName: "pages/_locale/account/orders/index" */))
const _83cf4e68 = () => interopDefault(import('../pages/_locale/account/personal-info/index.vue' /* webpackChunkName: "pages/_locale/account/personal-info/index" */))
const _1c00109e = () => interopDefault(import('../pages/_locale/account/reset-password/index.vue' /* webpackChunkName: "pages/_locale/account/reset-password/index" */))
const _35d4b1d4 = () => interopDefault(import('../pages/_locale/account/shopping-address/index.vue' /* webpackChunkName: "pages/_locale/account/shopping-address/index" */))
const _88212b54 = () => interopDefault(import('../pages/_locale/checkout/thank-you/index.vue' /* webpackChunkName: "pages/_locale/checkout/thank-you/index" */))
const _b0089130 = () => interopDefault(import('../pages/_locale/diary/all/index.vue' /* webpackChunkName: "pages/_locale/diary/all/index" */))
const _6dcdb0ca = () => interopDefault(import('../pages/_locale/diary/insights/index.vue' /* webpackChunkName: "pages/_locale/diary/insights/index" */))
const _5d999f90 = () => interopDefault(import('../pages/_locale/diary/inspirations/index.vue' /* webpackChunkName: "pages/_locale/diary/inspirations/index" */))
const _77bb96a9 = () => interopDefault(import('../pages/_locale/diary/projects/index.vue' /* webpackChunkName: "pages/_locale/diary/projects/index" */))
const _2ca7a59f = () => interopDefault(import('../pages/_locale/press-lounge/brand/index.vue' /* webpackChunkName: "pages/_locale/press-lounge/brand/index" */))
const _09dd7726 = () => interopDefault(import('../pages/_locale/press-lounge/collections/index.vue' /* webpackChunkName: "pages/_locale/press-lounge/collections/index" */))
const _1d255296 = () => interopDefault(import('../pages/_locale/diary/insights/_id/index.vue' /* webpackChunkName: "pages/_locale/diary/insights/_id/index" */))
const _1e096f23 = () => interopDefault(import('../pages/_locale/diary/inspirations/_id/index.vue' /* webpackChunkName: "pages/_locale/diary/inspirations/_id/index" */))
const _37b195d8 = () => interopDefault(import('../pages/_locale/diary/projects/_id/index.vue' /* webpackChunkName: "pages/_locale/diary/projects/_id/index" */))
const _7276cedc = () => interopDefault(import('../pages/_locale/landing/_id.vue' /* webpackChunkName: "pages/_locale/landing/_id" */))
const _5847a26c = () => interopDefault(import('../pages/_locale/moodboard/_id.vue' /* webpackChunkName: "pages/_locale/moodboard/_id" */))
const _1f79d36a = () => interopDefault(import('../pages/_locale/product/_id.vue' /* webpackChunkName: "pages/_locale/product/_id" */))
const _27804b73 = () => interopDefault(import('../pages/_locale/special-editions/_collection/concept/index.vue' /* webpackChunkName: "pages/_locale/special-editions/_collection/concept/index" */))
const _027a04e2 = () => interopDefault(import('../pages/_locale/special-editions/_collection/products/index.vue' /* webpackChunkName: "pages/_locale/special-editions/_collection/products/index" */))
const _342b6c1a = () => interopDefault(import('../pages/_locale/materials-and-installation/_material/_id.vue' /* webpackChunkName: "pages/_locale/materials-and-installation/_material/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _0eea7eb8,
    name: "index"
  }, {
    path: "/:locale",
    component: _0470dda2,
    name: "locale"
  }, {
    path: "/:locale/404",
    component: _942d6df0,
    name: "locale-404"
  }, {
    path: "/:locale/advanced-search",
    component: _71b36774,
    name: "locale-advanced-search"
  }, {
    path: "/:locale/bespoke-design-service",
    component: _c20cdfa6,
    name: "locale-bespoke-design-service"
  }, {
    path: "/:locale/brand-tools",
    component: _c6733fb0,
    name: "locale-brand-tools"
  }, {
    path: "/:locale/cart",
    component: _0d0efbfa,
    name: "locale-cart"
  }, {
    path: "/:locale/catalogues",
    component: _76af64e6,
    name: "locale-catalogues"
  }, {
    path: "/:locale/checkout",
    component: _67e038a9,
    name: "locale-checkout"
  }, {
    path: "/:locale/company",
    component: _18716e90,
    name: "locale-company"
  }, {
    path: "/:locale/confirm-account",
    component: _fa59c31a,
    name: "locale-confirm-account"
  }, {
    path: "/:locale/contacts",
    component: _06bd8b33,
    children: [{
      path: "",
      component: _3658c114,
      name: "locale-contacts"
    }, {
      path: ":id",
      component: _c6e1f144,
      name: "locale-contacts-id"
    }]
  }, {
    path: "/:locale/cookie-policy",
    component: _1a770164,
    name: "locale-cookie-policy"
  }, {
    path: "/:locale/creative-collection",
    component: _3badfa62,
    name: "locale-creative-collection"
  }, {
    path: "/:locale/diary",
    component: _41d82456,
    name: "locale-diary"
  }, {
    path: "/:locale/faq",
    component: _63b9242e,
    name: "locale-faq"
  }, {
    path: "/:locale/glamora-showroom",
    component: _51c3c29b,
    name: "locale-glamora-showroom"
  }, {
    path: "/:locale/info-and-legal",
    component: _681240c7,
    name: "locale-info-and-legal"
  }, {
    path: "/:locale/materials-and-installation",
    component: _03bb9194,
    name: "locale-materials-and-installation"
  }, {
    path: "/:locale/privacy-policy",
    component: _3317f18c,
    name: "locale-privacy-policy"
  }, {
    path: "/:locale/professional-consulting",
    component: _bd6ac9a2,
    name: "locale-professional-consulting"
  }, {
    path: "/:locale/professionals",
    component: _48fdc07f,
    name: "locale-professionals"
  }, {
    path: "/:locale/signup",
    component: _1ec8e00a,
    name: "locale-signup"
  }, {
    path: "/:locale/special-editions",
    component: _b6985ea8,
    name: "locale-special-editions"
  }, {
    path: "/:locale/terms-of-sale",
    component: _6af8c06c,
    name: "locale-terms-of-sale"
  }, {
    path: "/:locale/account/moodboards",
    component: _b63932ee,
    name: "locale-account-moodboards"
  }, {
    path: "/:locale/account/orders",
    component: _5ca7552a,
    name: "locale-account-orders"
  }, {
    path: "/:locale/account/personal-info",
    component: _83cf4e68,
    name: "locale-account-personal-info"
  }, {
    path: "/:locale/account/reset-password",
    component: _1c00109e,
    name: "locale-account-reset-password"
  }, {
    path: "/:locale/account/shopping-address",
    component: _35d4b1d4,
    name: "locale-account-shopping-address"
  }, {
    path: "/:locale/checkout/thank-you",
    component: _88212b54,
    name: "locale-checkout-thank-you"
  }, {
    path: "/:locale/diary/all",
    component: _b0089130,
    name: "locale-diary-all"
  }, {
    path: "/:locale/diary/insights",
    component: _6dcdb0ca,
    name: "locale-diary-insights"
  }, {
    path: "/:locale/diary/inspirations",
    component: _5d999f90,
    name: "locale-diary-inspirations"
  }, {
    path: "/:locale/diary/projects",
    component: _77bb96a9,
    name: "locale-diary-projects"
  }, {
    path: "/:locale/press-lounge/brand",
    component: _2ca7a59f,
    name: "locale-press-lounge-brand"
  }, {
    path: "/:locale/press-lounge/collections",
    component: _09dd7726,
    name: "locale-press-lounge-collections"
  }, {
    path: "/:locale/diary/insights/:id",
    component: _1d255296,
    name: "locale-diary-insights-id"
  }, {
    path: "/:locale/diary/inspirations/:id",
    component: _1e096f23,
    name: "locale-diary-inspirations-id"
  }, {
    path: "/:locale/diary/projects/:id",
    component: _37b195d8,
    name: "locale-diary-projects-id"
  }, {
    path: "/:locale/landing/:id?",
    component: _7276cedc,
    name: "locale-landing-id"
  }, {
    path: "/:locale/moodboard/:id?",
    component: _5847a26c,
    name: "locale-moodboard-id"
  }, {
    path: "/:locale/product/:id?",
    component: _1f79d36a,
    name: "locale-product-id"
  }, {
    path: "/:locale/special-editions/:collection?/concept",
    component: _27804b73,
    name: "locale-special-editions-collection-concept"
  }, {
    path: "/:locale/special-editions/:collection?/products",
    component: _027a04e2,
    name: "locale-special-editions-collection-products"
  }, {
    path: "/:locale/materials-and-installation/:material?/:id?",
    component: _342b6c1a,
    name: "locale-materials-and-installation-material-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
