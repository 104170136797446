import { render, staticRenderFns } from "./index.vue?vue&type=template&id=53ed43ec"
import script from "./index.vue?vue&type=script&setup=true&lang=js"
export * from "./index.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextHtml: require('/var/www/html/glamoraV2/frontend-2/components/text/html/index.vue').default,InputText: require('/var/www/html/glamoraV2/frontend-2/components/input/text/index.vue').default,BtnPrimary: require('/var/www/html/glamoraV2/frontend-2/components/btn/primary/index.vue').default,FormErrors: require('/var/www/html/glamoraV2/frontend-2/components/form/errors/index.vue').default,Loader: require('/var/www/html/glamoraV2/frontend-2/components/loader/index.vue').default})
