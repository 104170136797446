import { render, staticRenderFns } from "./index.vue?vue&type=template&id=db3f375e"
import script from "./index.vue?vue&type=script&setup=true&lang=js"
export * from "./index.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VectorIcon: require('/var/www/html/glamoraV2/frontend-2/components/vector/icon/index.vue').default,BtnSecondary: require('/var/www/html/glamoraV2/frontend-2/components/btn/secondary/index.vue').default,GridCss: require('/var/www/html/glamoraV2/frontend-2/components/grid/css/index.vue').default,Loader: require('/var/www/html/glamoraV2/frontend-2/components/loader/index.vue').default,Block: require('/var/www/html/glamoraV2/frontend-2/components/block/index.vue').default,BlocksWrapper: require('/var/www/html/glamoraV2/frontend-2/components/blocks-wrapper/index.vue').default,CardSearch: require('/var/www/html/glamoraV2/frontend-2/components/card/search/index.vue').default,Modal: require('/var/www/html/glamoraV2/frontend-2/components/modal/index.vue').default})
